/* eslint-disable promise/always-return */
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { MapWrapper } from 'components/google-maps/wrapper/MapWrapper'
import { useFormik } from 'formik'

import { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
	emptyUfinetSelectOption,
	Filter,
	IFilterState,
	UfinetBox,
	UfinetModal,
	UfinetSectionBox,
} from 'ufinet-web-components'

import {
	AuthContext,
	bulkQuotationService,
	clientService,
	FrontEndApplication,
	IBulkQuotationDetailsRequest,
	IBulkQuotationResponse,
	IClientFindRequest,
	IClientResponse,
	ILocalService,
	initialQuotationBulk,
	IQuotation,
	IQuotationConfirmRequest,
	IQuotationRepository,
	IQuotationRequest,
	IServicesDeletionRequest,
	IServicesRepository,
	IServiceUpdateRequest,
	onFormikChanges,
	populateLocalServiceFromServerResponse,
	populateServiceFromCalculateResponse,
	populateServiceFromUpdateResponse,
	ProcessedService,
	QuotationConfirmationStatus,
	quotationRequestFromServicesModel,
	quotationService,
	serviceCalculateRequestFromServiceModel,
	ServiceLocalState,
	serviceProductFromStateService,
	ServiceServerStatus,
	servicesService,
	serviceUpdateRequestFromServiceModel,
	silentFailureOptionsFetch,
	useInternalUser,
	useModal,
	useTranslator,
} from 'ufinet-web-functions'

import { useMapWrapper } from 'components/google-maps/wrapper/MapWrapperHook'
import { HttpPrefeasibilityRepository } from 'modules/prefeasibility/infrastructure/HttpPrefeasibilityRepository'
import { HttpTechnicalGroupRepository } from 'modules/prefeasibility/infrastructure/HttpTechnicalGroupRepository'
import { MassiveStatuses, MassiveStatusesLabel } from 'modules/quotation/massive/statuses'
import { v4 as uuidv4 } from 'uuid'
import { PATH_QUOTATION_MASSIVE } from '../../../routing/protected/PrivateRoutes'
import { QuotationCards } from '../common/components/QuotationCards'
import { ServiceTable } from '../common/components/service-table/ServiceTable'
import { NewServiceForm } from '../individual/components/NewServiceForm'

export interface IBulkQuotationDetailData {
	massiveRecordId: number
}

const BulkQuotationDetailPage: FC = () => {
	const intl = useIntl()
	const translate = useTranslator()
	const authData = useContext(AuthContext)

	const prefeasibilityRepository = useMemo(() => HttpPrefeasibilityRepository(authData), [authData])
	const technicalGroupRepository = useMemo(() => HttpTechnicalGroupRepository(authData), [authData])

	const internalUser = useInternalUser()

	const _bulkQuotationService = useMemo(() => bulkQuotationService(authData), [authData])
	const _clientService = useMemo(
		() => clientService(authData, { frontendApplication: FrontEndApplication.AQS }),
		[authData]
	)
	const _quotationService: IQuotationRepository = useMemo(() => quotationService(authData), [authData])
	const _serviceService: IServicesRepository = useMemo(() => servicesService(authData), [authData])

	const onChange = useCallback(onFormikChanges, [])
	const [resetFilter, setResetFilter] = useState(false)
	const resetFilterCallback = useCallback(() => {
		setResetFilter(false)
	}, [])

	const location: any = useLocation()
	const { massiveRecordId: pathId } = useParams()
	const massiveRecordId: number = location.state?.massiveRecordId || (pathId && parseInt(pathId))
	const [quotationRecordId, setQuotationRecordId] = useState<string>(location.state?.quotationRecordId)

	const [massiveQuotationData, setMassiveQuotationData] = useState<IQuotation>(initialQuotationBulk)
	const [quotationConfirmed, setQuotationConfirmed] = useState<boolean>(false)
	const [massiveQuotationServices, setMassiveQuotationServices] = useState<ProcessedService[]>([])
	const [servicesModDate, setServicesModDate] = useState<Date>(new Date())

	const [selectedCountry, setSelectedCountry] = useState<string>()
	const selectedCountryId = useRef<string>()
	const [_selectedCorporateGroup, setSelectedCorporateGroup] = useState<string>()
	const [selectedClient, setSelectedClient] = useState<string>()

	const [filterData, setFilterData] = useState<IFilterState>({})

	const [serviceModal, showServiceModal, hideServiceModal] = useModal()

	const [hasLoadedBulkData, setHasLoadedBulkData] = useState<boolean>(false)
	const [isTableUpdating, setIsTableUpdating] = useState(false)

	const navigate = useNavigate()

	// STARTUP FUNCTIONALITY

	useEffect(() => {
		if (massiveRecordId) {
			// There is a quotation record ID, we reached this page from the UI table and this bulk load belongs to user
			if (quotationRecordId) {
				loadMassiveQuotation(massiveRecordId)
				return
			}
			// We reached this page from a hardcoded URL and must check that this bulk load belongs to the user
			_bulkQuotationService
				.findAll({})
				.then((res: IBulkQuotationResponse) => res.map((r) => r.id))
				.then((ids) => {
					if (ids.includes(massiveRecordId)) loadMassiveQuotation(massiveRecordId)
					else throw new Error('Bulk ID does not exist or belong to user')
				})
				.catch(() => {
					returnOnMissingData()
				})
		} else {
			returnOnMissingData()
		}
	}, [])

	// FILTER/GLOBAL DATA FUNCTIONALITY

	const formik = useFormik<IFilterState>({
		initialValues: filterData,
		onSubmit: () => {},
		validateOnChange: true,
		validateOnBlur: true,
		enableReinitialize: true,
	})

	const setFilter = (filterData: IFilterState) => {
		onChange(formik, 'contactSelect')(filterData.contactSelect)
		onChange(formik, 'reference')(filterData.reference)
		onChange(formik, 'finalClient')(filterData.finalClient)
		showServiceModal()
	}

	const _unlockForm = () => {
		setMassiveQuotationData({ ...massiveQuotationData, confirmationStatus: QuotationConfirmationStatus.Unconfirmed })
	}

	// MASSIVE QUOTATION FUNCTIONALITY

	useEffect(() => {
		setQuotationConfirmed(massiveQuotationData.confirmationStatus === QuotationConfirmationStatus.Confirmed)
	}, [massiveQuotationData])

	const loadMassiveQuotation = (bulkId: number = massiveRecordId) => {
		setMassiveQuotationData({ ...massiveQuotationData, calculating: true })

		const req: IBulkQuotationDetailsRequest = { bulkId }
		_bulkQuotationService
			.find(req, silentFailureOptionsFetch)
			.then(({ _bulk, quotation }) => {
				const newQuotationData: IQuotation = {
					...massiveQuotationData,
					...quotation,
					currency:
						`${quotation.moneda.name || ''}${quotation.moneda.symbol ? ` (${quotation.moneda.symbol})` : ''}` ||
						intl.formatMessage({ id: 'UNKNOWN' }),
					calculating: false,
					outdated: false,
					startDate: new Date(),

					contact: quotation.contacto,
					reference: quotation.referencia,
					finalClient: quotation.finalClient,
				}

				const newFilterData: IFilterState = {
					clientSelect: { label: quotation.nombreCliente!, value: quotation.idCliente! },
					corporateGroupSelect: { label: quotation.nombreGrupoCorporativo!, value: quotation.idGrupoCorporativo! },
					countrySelect: { label: quotation.nombrePais!, value: quotation.idPais! },
					contactSelect: emptyUfinetSelectOption,
					reference: '',
					finalClient: quotation.finalClient ?? '',
				}

				setQuotationRecordId(newQuotationData.aqsId)
				setMassiveQuotationData(newQuotationData)
				loadInitialServices(newQuotationData)
				loadProjectStatus(newQuotationData)
				setFilterData(newFilterData)
			})
			.catch((_err) => {
				returnOnMissingData()
				setMassiveQuotationData({ ...massiveQuotationData, calculating: false })
			})
			.finally(() => setHasLoadedBulkData(true))
	}

	const confirmMassiveQuotation = (): Promise<void> => {
		setMassiveQuotationData({ ...massiveQuotationData, confirmationStatus: QuotationConfirmationStatus.Confirming })
		const req: IQuotationConfirmRequest = {
			id: massiveQuotationData.aqsId,
			contactId: formik.values?.contactSelect?.value || undefined,
			reference: formik.values.reference || undefined,
			finalClient: formik.values.finalClient || undefined,
		}
		return _quotationService
			.confirmQuotation(req, silentFailureOptionsFetch)
			.then((_res) => {
				setMassiveQuotationData({ ...massiveQuotationData, confirmationStatus: QuotationConfirmationStatus.Confirmed })
				toast.success(intl.formatMessage({ id: 'QUOTATION.CONFIRM.SENT.SUCCESS' }))
			})
			.catch((_err) => {
				setMassiveQuotationData({
					...massiveQuotationData,
					confirmationStatus: QuotationConfirmationStatus.Unconfirmed,
				})
				toast.error(intl.formatMessage({ id: 'QUOTATION.CONFIRM.ERROR' }))
			})
	}

	const returnOnMissingData = () => {
		toast.error(intl.formatMessage({ id: 'BULK_LOAD.OTHER' }))
		navigate(`/${PATH_QUOTATION_MASSIVE}`)
	}

	const loadProjectStatus = (quotationData: IQuotation = massiveQuotationData) => {
		_quotationService
			.calculateOfferStatus({ aqsId: quotationData.aqsId }, { notifyError: false })
			.then(
				({ status }) =>
					!!status &&
					status !== MassiveStatusesLabel[MassiveStatuses.NOT_VIABLE] &&
					setMassiveQuotationData({
						...quotationData,
						confirmationStatus: QuotationConfirmationStatus.Confirmed,
					})
			)
			.catch(() => toast.warn(translate('ERROR.QUOTATION.STATUS')))
	}

	const loadQuotationClient = (clientId: string) => {
		const req: IClientFindRequest = { clientId }
		_clientService
			.find(req)
			.then((client: IClientResponse) => {
				setSelectedCountry(client.countryId)
				selectedCountryId.current = client.countryId
				setSelectedCorporateGroup(client.corporateGroupId)
				setSelectedClient(client.id)
			})
			.catch(console.error)
	}

	// SERVICE FUNCTIONALITY

	const loadInitialServices = (quotationData: IQuotation = massiveQuotationData) => {
		setMassiveQuotationServices(
			quotationData.services.map(
				(service, idx) =>
					({
						...populateLocalServiceFromServerResponse(service, quotationData),
						pointNumber: idx,
						statusName: intl.formatMessage({
							id:
								service.status === ServiceServerStatus.CALCULATED
									? 'SERVICE.STATUS.CALCULATED'
									: 'SERVICE.STATUS.NOT_CALCULATED',
						}),
					} as ProcessedService)
			) || []
		)

		quotationData.idCliente && loadQuotationClient(quotationData.idCliente)
	}

	useEffect(() => {
		if (!hasLoadedBulkData) return
		setServicesModDate(new Date())
		setMassiveQuotationData({ ...massiveQuotationData, outdated: true })
		if (!massiveQuotationServices) return

		if (massiveQuotationServices.length === 0) {
			setMassiveQuotationData(initialQuotationBulk)
		} else if (massiveQuotationServices.every((s) => s.state === ServiceLocalState.Computed)) {
			calculateProject(massiveQuotationServices)
		}
	}, [massiveQuotationServices])

	const setNewService = (newServiceData: ILocalService, closeModal: boolean) => {
		const newService: ILocalService = {
			...newServiceData,
			state: ServiceLocalState.Created,
			temporaryId: newServiceData.temporaryId || uuidv4(),
			countrySelect: { ...formik.values.countrySelect },
			corporateGroupSelect: { ...formik.values.corporateGroupSelect },
			clientSelect: { ...formik.values.clientSelect },
			contactSelect: { ...formik.values.contactSelect },
			pointNumber: massiveQuotationServices.length,
		}
		newService.product = serviceProductFromStateService(newService)

		// Update UI with the data we already have
		setMassiveQuotationServices([...massiveQuotationServices, newService as ProcessedService])
		if (closeModal) hideServiceModal()

		// Request calculation in server
		postService(newService)
	}

	// Calculate single service
	const postService = (newService: ILocalService) => {
		const req = serviceCalculateRequestFromServiceModel(massiveQuotationData.aqsId, newService)

		_serviceService
			.calculateService(req, silentFailureOptionsFetch)
			.then((response) => {
				// Complete/overwrite service with server data
				const serverService: ProcessedService = {
					...populateServiceFromCalculateResponse(newService, response, intl),
					state: ServiceLocalState.Computed,
				}
				setMassiveQuotationServices((prevData) => {
					const currentServices = [...prevData] as ProcessedService[]

					// Find the corresponding service and complete it in state
					const serviceIndex = currentServices.findIndex((service) => service.temporaryId === serverService.temporaryId)
					if (serviceIndex !== -1) {
						currentServices.splice(serviceIndex, 1, serverService)

						if (serverService.status === ServiceServerStatus.CALCULATED) {
							toast.success(intl.formatMessage({ id: 'SERVICE.CALCULATE.CALCULATED' }))
						} else {
							if (internalUser) toast.warn(intl.formatMessage({ id: 'SERVICE.CALCULATE.WARNING' }))
						}
						return currentServices
					} else {
						console.warn(`Could not update service ${newService} with server response`)
						return prevData
					}
				})
			})
			.catch((err) => {
				console.error(err)
				// Remove from state
				const currentServices = [...massiveQuotationServices, newService] as ProcessedService[]
				setMassiveQuotationServices(currentServices.filter((s) => s.temporaryId !== newService.temporaryId))
				toast.error(intl.formatMessage({ id: 'SERVICE.CALCULATE.ERROR' }))
			})
	}

	const updateService = (updatedService: ProcessedService, preUpdatedService?: ProcessedService) => {
		// Update the affected service in state
		setIsTableUpdating(true)
		const updatedServices = massiveQuotationServices.map((service) =>
			service.pointNumber === updatedService.pointNumber
				? { ...updatedService, state: ServiceLocalState.Computing }
				: service
		)
		setMassiveQuotationServices(updatedServices)

		// Send update request
		const req: IServiceUpdateRequest = serviceUpdateRequestFromServiceModel(
			massiveQuotationData.aqsId,
			updatedService as ProcessedService,
			preUpdatedService as ProcessedService
		)

		return _serviceService
			.updateService(req, silentFailureOptionsFetch)
			.then((response) => {
				// Complete/overwrite service with server data
				const serverService: ProcessedService = {
					...populateServiceFromUpdateResponse(updatedService, response, intl),
					state: ServiceLocalState.Computed,
				}
				// Find the corresponding service and complete it in state
				const serviceIndex = massiveQuotationServices.findIndex((service) => service.id === serverService.id)
				if (serviceIndex !== -1) {
					const newServices = [...massiveQuotationServices]
					newServices.splice(serviceIndex, 1, serverService)
					setMassiveQuotationServices(newServices)
					if (serverService.status === ServiceServerStatus.CALCULATED)
						toast.success(intl.formatMessage({ id: 'SERVICE.UPDATE.UPDATED' }))
					else {
						if (internalUser) toast.warn(intl.formatMessage({ id: 'SERVICE.CALCULATE.WARNING' }))
					}
				} else {
					toast.warn(intl.formatMessage({ id: 'SERVICE.UPDATE.ERROR' }))
				}
			})
			.catch((err) => {
				console.error(err)
				// Return to base state before updates
				setMassiveQuotationServices(
					massiveQuotationServices.map((s) =>
						s.id === updatedService.id ? { ...s, state: ServiceLocalState.Computed } : s
					)
				)
				toast.error(intl.formatMessage({ id: 'SERVICE.UPDATE.ERROR' }))
			})
			.finally(() => setIsTableUpdating(false))
	}

	const deleteServices = (serviceIds: number[]): Promise<void> => {
		setIsTableUpdating(true)
		const newStateServices = massiveQuotationServices.map((service) => ({
			...service,
			state: serviceIds.includes(service.id!) ? ServiceLocalState.Deleting : service.state,
		}))
		setMassiveQuotationServices(newStateServices)

		const req: IServicesDeletionRequest = { aqsId: massiveQuotationData.aqsId, serviceIds }
		return _serviceService
			.deleteServices(req, silentFailureOptionsFetch)
			.then(() => {
				const servicesAfterDeletion = massiveQuotationServices
					.filter((s) => !serviceIds.includes(s.id!))
					.map((s, idx) => ({ ...s, pointNumber: idx }))
				setMassiveQuotationServices(servicesAfterDeletion)
				toast.success(intl.formatMessage({ id: 'SERVICE.DELETE.DELETED' }))

				if (servicesAfterDeletion.length === 0) returnOnNoServices()
			})
			.catch(() => {
				setMassiveQuotationServices(massiveQuotationServices.map((s) => ({ ...s, state: ServiceLocalState.Computed })))
				toast.error(intl.formatMessage({ id: 'SERVICE.DELETE.ERROR' }))
			})
			.finally(() => setIsTableUpdating(false))
	}

	const returnOnNoServices = () => {
		toast.info(intl.formatMessage({ id: 'BULK_LOAD.REMOVED' }))
		navigate(`/${PATH_QUOTATION_MASSIVE}`)
	}

	const calculateProject = (stateServices: ProcessedService[]) => {
		const quotationStartDate = new Date()
		setMassiveQuotationData({ ...massiveQuotationData, calculating: true, startDate: quotationStartDate })
		const req: IQuotationRequest = quotationRequestFromServicesModel(massiveQuotationData.aqsId, stateServices)

		_quotationService
			.calculateQuotation(req, silentFailureOptionsFetch)
			.then((res) => {
				// Quotation results may be outdated
				if (quotationStartDate >= servicesModDate) {
					const newProjectData = {
						...massiveQuotationData,
						...res,
						currency:
							`${res.moneda.name || ''}${res.moneda.symbol ? ` (${res.moneda.symbol})` : ''}` ||
							intl.formatMessage({ id: 'UNKNOWN' }),
						calculating: false,
						outdated: false,
						reference: massiveQuotationData.reference,
						finalClient: massiveQuotationData.finalClient,
					}
					setMassiveQuotationData(newProjectData)
					return newProjectData
				}
			})
			.catch(() => {
				toast.error(intl.formatMessage({ id: 'ERROR.QUOTATION' }))
				setMassiveQuotationData({ ...massiveQuotationData, calculating: false })
			})
	}

	// MAP FUNCTIONALITY

	const getServices = () => [...massiveQuotationServices]

	const {
		mapWrapperRef,
		mapControls,
		mapMarkers,
		mapOptions,
		mapKmzUrls,
		mapPolygons,
		mapDirections,
		onMapLoaded,
		onMapClick,
		onMapInfoWindowClose,
	} = useMapWrapper(formik, selectedCountry, '', getServices)

	if (!massiveRecordId) return <></>
	else
		return (
			<UfinetBox limitHeightToPageBottom={false} className="justify-content-center">
				<UfinetSectionBox className="mb-3">
					<h2 className="mb-0">
						{translate('BULK_LOAD.DETAIL')} - {massiveRecordId}
					</h2>
					{internalUser && (
						<h5 className="mb-0 mt-2">
							{translate('QUOTATION.ID')}: {quotationRecordId}
						</h5>
					)}
				</UfinetSectionBox>
				<UfinetSectionBox>
					<div>
						<Filter
							internalUser={internalUser}
							resetFilter={resetFilter}
							initialValues={filterData}
							resetFilterCallback={resetFilterCallback}
							setFilter={setFilter}
							afterContactChange={(contact) => onChange(formik, 'contactSelect')(contact)}
							afterReferenceChange={(reference) => onChange(formik, 'reference')(reference)}
							afterFinalClientChange={(finalClient) => onChange(formik, 'finalClient')(finalClient)}
							disabled={{
								allowCountrySelection: false,
								allowCorporateGroupSelection: false,
								allowClientSelection: false,
								allowSubmit: !!selectedClient && !quotationConfirmed && !isTableUpdating,
								allowContact: true,
								allowReference: true,
								allowFinalClient: true,
							}}
							required={{ requiredCountry: true }}
							hidden={{ hideContact: !internalUser, hideFinalClient: false }}
							icon={faPlus}
							submitButtonContent={intl.formatMessage({ id: 'SERVICE.NEW' })}
						/>
						{internalUser && <QuotationCards quotationData={massiveQuotationData} />}
					</div>
					<UfinetModal
						size="xxl"
						show={serviceModal}
						handleClose={hideServiceModal}
						title={intl.formatMessage({ id: 'SERVICE.NEW' })}
						preventCloseOnEscapeKey
					>
						<NewServiceForm
							setNewService={setNewService}
							corporateGroupId={formik.values.corporateGroupSelect?.value}
							country={formik.values.countrySelect}
							clientId={formik.values.clientSelect?.value}
						/>
					</UfinetModal>
					<div>
						<ServiceTable
							aqsId={massiveQuotationData.aqsId}
							internalUser={internalUser}
							hideButtons={{ prefeasibility: true }}
							services={massiveQuotationServices}
							prefeasibilityRepository={prefeasibilityRepository}
							technicalGroupRepository={technicalGroupRepository}
							quotationData={{ ...massiveQuotationData }}
							setModifiedService={(newServiceData, preUpdateServiceData) =>
								updateService(
									{ ...newServiceData, product: serviceProductFromStateService(newServiceData) },
									preUpdateServiceData
								)
							}
							deleteServices={deleteServices}
							confirmQuotation={confirmMassiveQuotation}
							countryId={selectedCountry ?? ''}
						/>
					</div>
					<div className="mt-10">
						<MapWrapper
							ref={mapWrapperRef}
							additionalStyles={{ height: '50vh' }}
							markers={mapMarkers}
							mapOptions={mapOptions}
							kmzUrls={mapKmzUrls}
							polygons={mapPolygons}
							focusOnLocation={false}
							onClick={onMapClick}
							onLoad={onMapLoaded}
							onInfoWindowClose={onMapInfoWindowClose}
							directions={mapDirections}
							controls={mapControls}
						/>
					</div>
				</UfinetSectionBox>
			</UfinetBox>
		)
}

export { BulkQuotationDetailPage }
